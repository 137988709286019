// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getCookie, setCookie } from "./cookie";
import { isLoggedIn, UpdateFCMToken } from "./API/auth";
import { setNotification } from "./Notifications";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

getAnalytics(app);

export const messaging = getMessaging();

// If we receive a push token from the backend, save it to cookies and send it to the database
export function setIosTokenHandler() {
  document.addEventListener("push-token", function (event) {
    console.log("Captured", event.detail);
    if (getCookie("is_debug_mode") === "true") {
      setNotification("Push token: " + event.detail);
    }

    setCookie("ios_token", event.detail, 30);
    setCookie("notifications_blocked_2", "true", 30); // this blocks browser notif request
    let user = isLoggedIn();
    if (user) {
      UpdateFCMToken(event.detail);
    } else {
    }
  });
}

export async function runFirebase() {
  // eliminate OneSignal service worker
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        if (
          registration.active &&
          registration.active.scriptURL.includes("OneSignalSDKWorker.js")
        ) {
          registration.unregister();
        }
      }
    });
  }

  let user = isLoggedIn();

  if (user) {
    if (!getCookie("ios_token")) {
      requestForToken().then(async (token) => {
        await UpdateFCMToken(token);
      });
    } else {
      setCookie("notifications_blocked_2", "true", 30);
      UpdateFCMToken(getCookie("ios_token"));
    }
  }

  if (
    typeof Notification !== "undefined" &&
    Notification &&
    Notification.permission !== "granted"
  ) {
    setCookie("attempt_request_notification_2", "true", 1);
  }
}

export const requestForToken = async () => {
  let currentToken = null;
  try {
    currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });
  } catch (err) {
    console.log("error");
    if (getCookie("is_debug_mode") === "true") {
      alert(JSON.stringify(err));
    }
    return null;
  }

  if (currentToken) {
    return currentToken;
  }

  console.log("error");
  return null;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
